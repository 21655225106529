<template>
  <div>
    <div class="head">
      <div class="box">
        <img src="../../assets/img/team/img-bg.png"
          class="img" />
      </div>
    </div>
    <div class="content1">
      <div class="top">
        <div class="title">团队介绍</div>
        <div class="line"></div>
        <div class="title1">TEAM TO INTRODUCE</div>
      </div>
      <div class="bom">
        <div class="left-item">
          <div class="item"
            v-for="item in teamList1"
            :key="item.index">
            <div class="textbox">
              <div class="box">
                <div class="title">{{ item.title }}</div>
                <div class="line"></div>
                <div class="text">{{ item.text }}</div>
              </div>
            </div>
            <img :src="item.img"
              class="img" />
          </div>
        </div>
        <div class="right-item">
          <div class="item"
            v-for="item in teamList2"
            :key="item.index">
            <img :src="item.img"
              class="img" />
            <div class="textbox">
              <div class="box">
                <div class="title">{{ item.title }}</div>
                <div class="line"></div>
                <div class="text">{{ item.text }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content4">
      <div class="top">
        <div class="title">企业文化</div>
        <div class="line"></div>
        <div class="title1">THE ENTERPRISE CULTURE</div>
      </div>
      <div class="bom">
        <div class="item"
          v-for="(item, index) in cultureList"
          :key="index"
          @mouseleave="mouseLeave"
          @mouseover="mouseOver(item.index)">
          <div class="outside animated"
            :class="{ fadeOutDown: index == curIndex }">
            <img :src="item.img"
              class="img" />
            <div class="imgb"></div>
            <div class="text">
              <div>
                <div class="text1">{{ item.text1 }}</div>
                <div class="text2">{{ item.text2 }}</div>
              </div>
            </div>
          </div>
          <div class="inside animated"
            :class="{ fadeInUp: index == curIndex }">
            <div class="text3">
              {{ item.text3 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content2">
      <div class="top">
        <div class="title">核心优势</div>
        <div class="line"></div>
        <div class="title1">CORE STRENGTHS</div>
      </div>
      <div class="bom">
        <div class="item"
          v-for="(item, index) in youshiList"
          :key="index">
          <img :src="item.icon"
            class="icon" />
          <div class="title">{{ item.title }}</div>
          <div class="text">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <div class="content3">
      <div class="top">
        <div class="title">服务宗旨</div>
        <div class="line"></div>
        <div class="title1">SERVICE TENET</div>
      </div>
      <div class="bom">
        <div class="bom-box">
          <div class="item"
            v-for="(item, index) in purposeList1"
            :key="index">
            <div class="left">
              <img :src="item.img"
                class="img" />
            </div>
            <div class="right">
              <div class="title">{{ item.title }}</div>
              <div class="line"></div>
              <div class="text">{{ item.text }}</div>
            </div>
          </div>
        </div>
        <div class="bom-box">
          <div class="item"
            v-for="(item, index) in purposeList2"
            :key="index">
            <div class="left">
              <img :src="item.img"
                class="img" />
            </div>
            <div class="right">
              <div class="title">{{ item.title }}</div>
              <div class="line"></div>
              <div class="text">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <floatBar></floatBar>
  </div>
</template>

<script>
import floatBar from "../../components/FloatBar";
export default {
  metaInfo: {
    title: "地道益农",
    meta: [
      {
        name: "Team",
        content: "服务团队",
      },
    ],
  },
  components: { floatBar },
  props: {},
  data() {
    return {
      teamList1: [
        {
          img: require("../../assets/img/team/team-1.png"),
          title: "运营策划部",
          text: "拥有“地道益农”微信公众号、微博，微信红人朋友圈等自有账号，；定制品牌营销、活动策划等一站式全案服务；拥有“地道集市”服务号及小程序，提供生活服务类优选推荐",
        },
        {
          img: require("../../assets/img/team/team-2.png"),
          title: "视频宣传部",
          text: "摄影摄像、 活动现场的图片直播、TVC广告、公益广告制作、宣传片、记录片、VLOG的拍摄，抖音、快闪视频的策划制作、MG动画制作、微电影、MV的拍摄剪辑等",
        },
        {
          img: require("../../assets/img/team/team-3.png"),
          title: "人才事业部",
          text: "线上招聘渠道：微信公众号、小程序、移动端、pc网页端；线下招聘会活动：活动策划、活动宣传、线下执行；企业招聘定制：根据企业需求量身定制专业的招聘方案",
        },
      ],
      teamList2: [
        {
          img: require("../../assets/img/team/team-4.png"),
          title: "政务策划部",
          text: "多年与政府单位合作经验，承接运营市、县、街道、社区四级政务新媒体平台，具有丰富的文案写作、选题策划、内容编辑等综合能力。下设策划、采访、编辑小组，始终坚持正确的政治方向、独特的选题风格、独家的新闻视点",
        },
        {
          img: require("../../assets/img/team/team-5.png"),
          title: "技术设计部",
          text: "前端后端技术开发、网站建设、小程序开发、H5定制、微官网制作、营销互动类游戏开发、平面物料类设计制作、小程序及Wed网页设计、logo及vi设计、IP定制、PPT及画册类制作、漫画手绘设计",
        },
        {
          img: require("../../assets/img/team/team-6.png"),
          title: "综合事务部",
          text: "起草、审核、发布公司文件、通知及公告，完善和修订公司的各项内部管理规章制度，监督和考核各部门执行情况；制定和完善公司人事制度。完成公司制定的部门年度工作任务负责招聘、劳动合同管理、薪酬福利、培训及员工活动等工作，办理员工入离职手续，管理员工档案",
        },
      ],
      youshiList: [
        {
          index: 0,
          icon: require("../../assets/img/team/img-ys11.png"),
          title: "专业性强",
          text: "有一支年轻、有活力、有脑洞的团队，创意灵感源源不绝",
        },
        {
          index: 1,
          icon: require("../../assets/img/team/img-ys12.png"),
          title: "资源丰富",
          text: "拥有一网双微等多个新媒体平台，更有微信红人朋友圈，宣传渠道丰富。",
        },
        {
          index: 2,
          icon: require("../../assets/img/team/img-ys13.png"),
          title: "团队协作性强",
          text: "公司团队以90后为主，团队成员团结友爱、协作力强",
        },
        {
          index: 3,
          icon: require("../../assets/img/team/img-ys14.png"),
          title: "执行力强",
          text: "公司遵循“高效、快速、精准”的服务宗旨，高效快速的执行客户方案",
        },
      ],
      purposeList1: [
        {
          index: 0,
          img: require("../../assets/img/team/purpose-1.png"),
          title: "快速",
          text: "及时快速地为客户提供优质的互联网服务",
        },
        {
          index: 1,
          img: require("../../assets/img/team/purpose-2.png"),
          title: "有效",
          text: "为客户提供有效的服务提案和服务策划",
        },
        {
          index: 2,
          img: require("../../assets/img/team/purpose-3.png"),
          title: "创新",
          text: "不断创新，提升企业综合服务能力",
        },
      ],
      purposeList2: [
        {
          index: 3,
          img: require("../../assets/img/team/purpose-4.png"),
          title: "诚信",
          text: "按时保质保量为客户提供互联网服务",
        },
        {
          index: 4,
          img: require("../../assets/img/team/purpose-5.png"),
          title: "精益求精",
          text: "不断提升员工能力，将服务做到最好",
        },
        {
          index: 5,
          img: require("../../assets/img/team/purpose-6.png"),
          title: "专业",
          text: "有较强的专业理论知识和运营实战经验",
        },
      ],
      curIndex: null,
      cultureList: [
        {
          img: require("@/assets/img/index/img11.png"),
          index: 0,
          text1: "诚实守信",
          text2: "Honest and trustworthy",
          text3:
            "诚实守信：诚实做人，不隐瞒自己的真实思想。不为不可告人的目的欺瞒客户、同事。讲信用，信守承诺，忠实于自己承担的义务。",
        },
        {
          img: require("@/assets/img/index/img12.png"),
          index: 1,
          text1: "戒骄戒躁",
          text2: "Avoid arrogance",
          text3:
            "获得成就，不要骄傲自满。遇到挫折，不要慌乱急躁、一蹶不振，要一如既往的保持谦逊进取的精神。",
        },
        {
          img: require("@/assets/img/index/img13.png"),
          index: 2,
          text1: "团结创新",
          text2: "Unity innovation",
          text3: "互相支持，互相配合，互相理解，互相包容。",
        },
        {
          img: require("@/assets/img/index/img14.png"),
          index: 3,
          text1: "拥抱变化",
          text2: "Embrace change",
          text3:
            "面对变化，理性对待，充分沟通，诚意配合。对变化产生的困难和挫折，能自我调整，并正面影响和带动同事。有前瞻意识，创造变化，建立新方法、新思路。",
        },
      ],
      curIndex1: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    mouseOver(i) {
      this.curIndex = i;
    },
    mouseLeave() {
      this.curIndex = null;
    },
    mouseOver1(i) {
      this.curIndex1 = i;
    },
    mouseLeave1() {
      this.curIndex1 = null;
    },
    toTop() {
      let time = setInterval(() => {
        document.documentElement.scrollTop += -50;
        if (document.documentElement.scrollTop === 0) {
          clearInterval(time);
        }
      }, 10);
    },
  },
  created() {
    this.toTop();
  },
  mounted() { },
};
</script>
<style lang="less" scoped>
.head {
  width: 100%;
  height: 368px;
  padding-top: 68px;
  .box {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .img {
      width: 100%;
      height: 300px;
      position: absolute;
      z-index: -1;
    }
    .text {
      font-size: 36px;
      font-weight: bold;
      color: #fff;
    }
  }
}
.top {
  text-align: center;
  .title {
    color: #000028;
    font-size: 24px;
  }
  .line {
    width: 40px;
    height: 1px;
    background: #ef8200;
    opacity: 1;
    margin: 0 auto;
    margin-top: 8px;
    margin-bottom: 7px;
  }
  .title1 {
    font-size: 14px;
    color: #999999;
  }
}
.content1 {
  margin-top: 70px;
  .bom {
    width: 100%;
    margin: 0 auto;
    margin-top: 60px;
    padding: 0 100px;
    margin-bottom: 80px;
    border-radius: 10px;
    display: flex;
    justify-content: space-around;
    .left-item {
      .item {
        width: 50%;
        border-radius: 10px;
        .img {
          width: 700px;
          height: 327px;
        }
        .textbox {
          width: 700px;
          height: 327px;
          display: flex;
          justify-content: center;
          align-items: center;
          .box {
            width: 670px;
            margin-left: 50px;
            .title {
              color: #000028;
              font-weight: 500;
              font-size: 20px;
            }
            .line {
              width: 50px;
              height: 2px;
              background: #ef8200;
              margin-top: 10px;
              margin-bottom: 28px;
            }
            .text {
              color: #000014;
              font-size: 16px;
            }
          }
        }
      }
    }
    .right-item {
      .item {
        width: 50%;
        border-radius: 10px;
        .img {
          width: 700px;
          height: 327px;
        }
        .textbox {
          width: 700px;
          height: 327px;
          display: flex;
          justify-content: center;
          align-items: center;
          .box {
            width: 670px;
            margin-left: 50px;
            .title {
              color: #000028;
              font-weight: 500;
              font-size: 20px;
            }
            .line {
              width: 50px;
              height: 2px;
              background: #ef8200;
              margin-top: 10px;
              margin-bottom: 28px;
            }
            .text {
              color: #000014;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
.content2 {
  width: 100%;
  height: 470px;
  background: #f0f5fa;
  .top {
    text-align: center;
    .title {
      color: #000028;
      font-size: 24px;
    }
    .line {
      width: 40px;
      height: 1px;
      background: #ef8200;
      opacity: 1;
      margin: 0 auto;
      margin-top: 8px;
      margin-bottom: 7px;
    }
    .title1 {
      font-size: 14px;
      color: #999999;
    }
  }
  padding-top: 30px;
  .bom {
    height: 380px;
    margin: 0 auto;
    margin-left: 350px;
    margin-right: 350px;
    margin-top: 60px;
    display: flex;
    justify-content: space-around;
    .item {
      width: 216px;
      height: 250px;
      position: relative;
      text-align: center;
      .icon {
        width: 100px;
        height: 100px;
      }
      .title {
        font-size: 20px;
        margin-top: 40px;
        color: #000028;
        font-weight: 800;
      }
      .text {
        font-size: 16px;
        margin-top: 18px;
        color: #000014;
        text-align: left;
      }
    }
  }
}
.content3 {
  height: 483px;
  padding-top: 70px;
  width: 100%;
  .bom {
    margin: 0 auto;
    margin-top: 60px;
    .bom-box {
      display: flex;
      justify-content: space-around;
      margin-left: 350px;
      margin-right: 350px;
      .item {
        width: 250px;
        height: 76px;
        border-radius: 10px;
        margin-bottom: 54px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .img {
          width: 60px;
          height: 60px;
          position: relative;
          top: 15px;
        }
        .right {
          margin-left: 18px;
          .title {
            font-size: 20px;
            font-weight: 500;
            color: #000028;
          }
          .line {
            width: 44px;
            height: 2px;
            background: #ef8200;
            margin-top: 6px;
            margin-bottom: 12px;
          }
          .text {
            font-size: 14px;
            font-weight: 400;
            color: #4d4d4d;
          }
        }
      }
    }
  }
}
.content4 {
  padding-top: 70px;
  width: 100%;
  height: 760px;
  .bom {
    width: 100%;
    height: 500px;
    margin: 0 auto;
    display: flex;
    margin-top: 60px;
    justify-content: space-between;
    .item {
      overflow: hidden;
      width: 350px;
      height: 500px;
      border-radius: 10px;
      .outside {
        z-index: 10;
        position: relative;
        border-radius: 10px;
        .img {
          width: 350px;
          height: 500px;
          position: absolute;
          z-index: 10;
        }
        .imgb {
          width: 350px;
          height: 500px;
          background: rgba(0, 64, 159, 0.5);
          position: absolute;
          z-index: 11;
        }
        .text {
          width: 350px;
          height: 500px;
          position: absolute;
          z-index: 20;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          .text1 {
            font-size: 20px;
            font-weight: 500;
            text-align: center;
          }
          .text2 {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
      .inside {
        position: relative;
        z-index: 9;
        width: 350px;
        height: 500px;
        background: #ef8200;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        .text3 {
          text-align: justify;
          height: 220px;
          width: 262px;
          line-height: 24px;
          color: #ffffff;
          font-size: 16px;
          border-top: 2px solid #fff;
          border-bottom: 2px solid #fff;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>
